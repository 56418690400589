<template>
  <v-dialog v-model="show" width="80%" persistent scrollable>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-card-title class="headline">
          Novo Processo
        </v-card-title>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-form
            ref="edicaoProcessoForm"
            @submit.prevent="criarProcesso"
        >
          <ProcessoForm v-model='processoSelecionado'/>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn color="error" @click.prevent="show = false">
                Cancelar
              </v-btn>
              <v-btn color="primary" type="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProcessoForm from "@/components/base/forms/ProcessoForm";
import processoService from "@/services/processo.service";
import {mapActions} from "vuex";

export default {
  name: "CriarProcessoDialog",

  props: {
    value: {type: Boolean, required: true},
  },

  components: {
    ProcessoForm
  },

  watch: {
    show: {
      handler() {
        this.$emit('input', this.show);
      }
    }
  },

  data() {
    return {
      show: this.value,
      processoSelecionado: {},
    }
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),

    irParaProcessoSelecionado(oid) {
      if (oid) {
        this.$router.push({
          name: 'ProcessoSelecionado',
          params: {
            oid
          }
        });
      }
    },

    async criarProcesso() {
      if (this.$refs.edicaoProcessoForm.validate()) {
        let loader = this.$loading.show();

        if (this.processoSelecionado.especificacoes) {
          if (!this.processoSelecionado.especificacoes.find(especificacao => especificacao.codigo === 'PNE')) {
            this.processoSelecionado.prefixoNumeroEnem = null;
          }

          if (!this.processoSelecionado.especificacoes.find(especificacao => especificacao.codigo === 'UO')) {
            this.processoSelecionado.anoIngressoUniversidadeOrigem = null;
          }
        }

        await processoService
            .criarProcesso(this.processoSelecionado)
            .then((response) => {
              this.exibirAviso({
                mensagem: "Processo criado com sucesso!",
                tipo: "success"
              });

              this.irParaProcessoSelecionado(response.data.oid);
            })
            .catch((error) => {
              let mensagem = "Não foi possível realizar o cadastro!";
              if (error.response.status === 409) {
                mensagem = "Cadastro não realizado, pois já existe um processo com mesmo nome, ano e sequência!";
              }
              this.exibirAviso({
                mensagem,
                tipo: "error"
              });
            });

        loader.hide();
      }
    }
  }
};
</script>

<style scoped></style>
