<template>
  <v-container fluid>
    <v-row justify="center">
      <v-card rounded width="100%">
        <v-card-title class="justify-center mb-3 titulo">
          <h2>
            Processos
          </h2>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <EscolhaProcessoAutocomplete
                    v-model="processoSelecionado"
                    :processos="processos"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <div
                  v-if="!possuiProcessoSelecionado"
                  class="title grey--text text--lighten-1 font-weight-light text-center"
              >
                Selecione um processo
                <br/>ou<br/>
                <v-btn color="accent"
                       @click="dialog = true;">
                  <v-icon left>mdi-plus-thick</v-icon>
                  Crie um Processo
                </v-btn>
              </div>
              <v-btn v-else color="primary"
                     :disabled="!possuiProcessoSelecionado"
                     @click="irParaProcessoSelecionado">
                Selecionar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
    <CriarProcessoDialog v-if="dialog" v-model="dialog" />
  </v-container>
</template>

<script>
import _ from "lodash";
import EscolhaProcessoAutocomplete from "@/components/base/EscolhaProcessoAutocomplete";
import processoService from "@/services/processo.service"
import CriarProcessoDialog from "@/components/pages/admin/processos/CriarProcessoDialog";

export default {
  name: "Processo",

  components: {
    CriarProcessoDialog,
    EscolhaProcessoAutocomplete,
  },

  data() {
    return {
      dialog: false,
      processos: [],
      processoSelecionado: {}
    };
  },

  async created() {
    let loader = this.$loading.show();

    await this.recuperarProcessos();

    loader.hide();
  },

  computed: {
    possuiProcessoSelecionado() {
      return !_.isEmpty(this.processoSelecionado);
    }
  },

  methods: {
    async recuperarProcessos() {
      await processoService
          .recuperarProcessosConsulta()
          .then(response => {
            this.processos = response.data;
          })
          .catch(() => {
          });
    },

    irParaProcessoSelecionado() {
      if (this.processoSelecionado.oid) {
        this.$router.push({
          name: 'ProcessoSelecionado',
          params: {
            oid: this.processoSelecionado.oid
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
